<template>
  <v-container class="pt-1 pb-0" fluid>
    <base-material-card inline icon="mdi-clipboard-text" :title="$t('Templates')">
      <v-data-table
        class="elevation-0"
        :headers="headers"
        :items="items"
        :page.sync="page"
        :server-items-length="totalResults"
        :items-per-page.sync="itemsPerPage"
      >
        <template v-slot:top>
          <div>
            <v-btn icon @click="reloadData">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="addItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="generateVariable(item)">
            mdi-text-box-check
          </v-icon>
          <v-icon small class="mr-2" @click="downloadDocument(item)">
            mdi-download
          </v-icon>
          <v-icon small class="mr-2" @click="generateDocument(item)">
            mdi-page-next
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <add-document ref="addData" @reload="reloadData" />
      <generate-variable ref="generateVariable" />
      <generate-document ref="generateDocument" />
    </base-material-card>
  </v-container>
</template>
<script>
import axios from "../../plugins/axios"
import AddDocument from "./AddDocument"
import GenerateDocument from "./GenerateDocument"
import GenerateVariable from "./GenerateVariable"
export default {
  components: { AddDocument, GenerateDocument, GenerateVariable },
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Path", value: "path" },
      { text: "Access", value: "access" },
      { text: "Acțiuni", value: "action", sortable: false, align: "right" }
    ],
    totalResults: 0,
    page: 1,
    itemsPerPage: 10,

    items: []
  }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  watch: {
    $route(to) {
      if (to.name.indexOf("Templates") === 0) this.reloadData()
    },
    page() {
      this.$log("page ", this.page)
      this.reloadData()
    },
    itemsPerPage() {
      this.$log("itemsPerPage ", this.itemsPerPage)
      if (this.page === 1) {
        this.reloadData()
      } else {
        this.page = 1
      }
    }
  },
  created() {
    this.reloadData()
  },
  methods: {
    generateVariable(item) {
      this.$refs.generateVariable.editData(item)
    },
    downloadDocument(item) {
      this.$log("down ", axios.defaults.baseURL + "app_template_download/?f=" + item._id)
      window.open(axios.defaults.baseURL + "app_template_download/?f=" + item._id, "_parent")
      // this.$refs.generateDocument.editData(item)
    },
    generateDocument(item) {
      this.$refs.generateDocument.editData(item)
    },
    editItem(item) {
      this.$refs.addData.editData(item)
    },
    addItem() {
      this.$refs.addData.addData()
    },
    deleteItem(item) {
      // axios.get("app_template/" + item._id).then(response => {
      axios
        .delete("app_template/" + item._id, {
          headers: {
            "If-Match": item._etag
          }
        })
        .then(res => {
          if (res.status === 204) this.reloadData()
        })
      // })
    },
    reloadData() {
      axios
        .get(
          'app_template?where={"$or":[{"company":"' +
            this.companyId +
            '"},{"access":"public"}]}&projection={"name":1,"access":1,"path":1,"_etag":1}&max_results=' +
            this.itemsPerPage +
            "&page=" +
            this.page
        )
        .then(response => {
          // this.$log("schema reload", response)
          this.items = response.data._items
          this.totalResults = response.data._meta.total
        })
    }
  }
}
</script>
