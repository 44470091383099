<template>
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <v-card>
      <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-card-title> {{ $t("generateDocument") }}{{ " - " + title }} </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-text-field v-model="title" autofocus :label="$t('document name')" :rules="inputRequired" />
        </v-form>
        <v-tabs v-model="tab">
          <v-tab v-for="itm in fieldsValue" :key="itm">
            {{ itm }}
          </v-tab>
        </v-tabs>
        <v-btn v-if="fieldsValue[tab] != 'Other'" @click="searchLink">
          {{ "search value for " + fieldsValue[tab] }}
        </v-btn>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  File field
                </th>
                <th class="text-left">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="itm in record[fieldsValue[tab]]" :key="itm.variable">
                <td>{{ itm.variable }}</td>
                <td>
                  <v-text-field v-model="itm.default" dense hide-details />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <search-link ref="searchLink" selectEvent @select="setFieldValue" />
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import SearchLink from "../../components/containers/SearchLink"
import { EventBus } from "../../EventBus"

export default {
  components: { SearchLink },
  data: () => ({
    tab: 0,
    tabCol: 0,
    record: {},
    fieldsValue: [],
    variables: [],
    newVariables: [],
    selectedCollections: [],
    collectionsFields: {},
    newVariablesCollection: {},
    recordId: "",
    collectionsValues: {},
    etag: "",
    show: false,
    title: ""
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  methods: {
    searchLink() {
      this.$log("searchLink ", this.collectionsValues[this.fieldsValue[this.tab]])
      this.$refs.searchLink.open("ent_" + this.collectionsValues[this.fieldsValue[this.tab]])
    },
    setFieldValue(e) {
      this.$log("setFieldValue ", e)
      //const res = this.appSchema.filter(itm => itm.collection === this.collectionsValues[this.fieldsValue[this.tab]])[0]
      //axios.get(res.item_url + "/" + e).then(response => {
      // this.$log("resp cl1 ", response)
      this.record[this.fieldsValue[this.tab]].map(itm => {
        itm.default = this.resolvePath(itm.field, e)
        //})
        this.$set(this.record, "", { ...this.record })
      })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.title = e.name
      this.collectionsValues = {}
      this.record = {}
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
      this.$log("etag ", e)
      axios.get("app_template/" + this.recordId).then(response => {
        this.$log("app_template", response)
        this.variables = response.data.variables.variables
        // let record = { variables: this.variables, collections: this.collectionsValues, fields: this.newVariables }
        this.collectionsValues = response.data.variables.collections
        this.newVariables = response.data.variables.fields
        this.fieldsValue = []
        for (const itm in this.collectionsValues) {
          if (this.collectionsValues[itm] !== "") {
            this.fieldsValue.push(itm)
            this.record[itm] = []
            this.variables.map(item => {
              const col = item.split("_")[0]
              const fld = item.replace(col + "_", "")
              if (col === itm) {
                this.record[itm].push({
                  variable: item,
                  field: this.newVariables[this.collectionsValues[itm]][this.collectionsValues[itm] + " " + fld].field,
                  default: this.newVariables[this.collectionsValues[itm]][this.collectionsValues[itm] + " " + fld]
                    .default
                })
              }
            })
          } else {
            if (this.record.Other === undefined) this.record.Other = []
            this.variables.map(item => {
              const col = item.split("_")[0]
              //const fld = item.replace(col + "_", "")
              if (col === itm) {
                this.record.Other.push({
                  variable: item,
                  field: "",
                  default: ""
                  // default: this.newVariables[this.collectionsValues[itm]][item].default
                })
              }
            })
          }
        }
        this.$log("record ", this.record)
        this.fieldsValue.push("Other")
        this.$log("this.variables ", this.variables)
        this.$log("this.collectionsValues ", this.collectionsValues)
        this.etag = response.data._etag
      })
    },
    saveData() {
      this.$log("this.variables1 ", this.record)
      if (this.$refs.dataForm.validate()) {
        const record = {}
        record.id_app_template = this.recordId
        record.doc_name = this.title.split(" ").join("_")
        record.company_id = this.companyId
        record.table_values = {}
        for (const itm in this.record) {
          // this.$log("itm ", itm)
          if (itm) {
            this.record[itm].map(item => {
              record.table_values[item.variable] = item.default != null ? item.default : ""
            })
          }
        }
        this.$log("record ", record)
        axios
          .post("app_template_exec/", record)
          .then(response => {
            this.$log("saveData", response)
            if (response.status === 200) {
              window.open(axios.defaults.baseURL + "app_doc_download/?f=" + response.data.id_app_doc, "_parent")
              this.show = false
            }
          })
          .catch(error => {
            this.$log("error ", error)
            EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
          })
      }
    }
  }
}
</script>
