<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span>
          {{ record.title }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col cols="12" sm="6" md="4">
            <div>{{ $t("DOI") }}</div>
            <div v-if="record.ids" class="text-h4">{{ record.ids.doi }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>{{ $t("Scopus id") }}</div>
            <div class="text-h4">{{ record.scopus_id }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>{{ $t("Author id") }}</div>
            <div class="text-h4">{{ record.author_id }}</div>
          </v-col>
          <v-col cols="12" sm="8">
            <div>{{ $t("Authors") }}</div>
            <div class="d-flex">
              <div v-for="author in record.authors" :key="author.order_id" class="ml-2">
                <v-chip class="text-h4" small label>{{ author.name }}</v-chip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div>{{ $t("Scopus insert date") }}</div>
            <div v-if="record.scopus_insert_date" class="text-h4">{{ record.scopus_insert_date.split("T")[0] }}</div>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Publisher") }}</v-subheader>
          </v-col>
          <v-col v-if="record.publisher" cols="12">
            <span>{{ record.publisher.source_title }}</span>
            <span v-if="record.publisher.volume" class="ml-1">({{ record.publisher.volume }})</span>
            <span v-if="record.publisher.page_start">
              (pp.{{ record.publisher.page_start + "-" + record.publisher.page_end }})
            </span>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Abstract") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            {{ record.abstract }}
          </v-col>
          <v-col v-if="record.publisher['additional-info']" cols="12">
            <v-subheader>{{ $t("Conference") }}</v-subheader>
          </v-col>
          <v-col v-if="record.publisher['additional-info']" cols="12">
            <div>{{ $t("Name") }}</div>
            <div v-if="record.publisher['additional-info'].confevent" class="text-h4">
              {{ record.publisher["additional-info"].confevent.confname }}
            </div>
          </v-col>
          <v-col v-if="record.publisher['additional-info']" cols="12" sm="6" md="4">
            <div>{{ $t("Date") }}</div>
            <div v-if="record.publisher['additional-info'].confevent" class="text-h4">
              {{
                record.publisher["additional-info"].confevent.confdate.startdate +
                  " - " +
                  record.publisher["additional-info"].confevent.confdate.enddate
              }}
            </div>
          </v-col>
          <v-col v-if="record.publisher['additional-info']" cols="12" sm="6" md="4">
            <div>{{ $t("Location") }}</div>
            <div v-if="record.publisher['additional-info'].confevent" class="text-h4">
              {{ record.publisher["additional-info"].confevent.conflocation.city }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
import Templates from "../../documents/Templates.vue"
export default {
  name: "DashboardDashboard",
  components: { Templates },
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,

      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Scopus publications",
          disabled: false,
          href: "/scopus-publications"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "ScopusPublication") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get('infuni_scopus_publications?where={"scopus_id":"' + this.id + '"}')
        .then(response => {
          this.record = response.data._items[0]
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
