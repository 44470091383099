<template>
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <v-card>
      <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-card-title> {{ $t("generateVariable") }}{{ " - " + title }} </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab>{{ $t("tables") }}</v-tab>
          <v-tab>{{ $t("fields") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      File table
                    </th>
                    <th class="text-left">
                      Database table
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itm, nam) in collectionsValues" :key="nam">
                    <td>{{ nam }}</td>
                    <td>
                      <v-autocomplete
                        v-model="collectionsValues[nam]"
                        dense
                        hide-details
                        item-text="title"
                        item-value="collection"
                        :items="tables"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-tabs v-model="tabCol">
              <v-tab v-for="col in selectedCollections" :key="col">
                {{ gettablesName(col) + " - " + tabCol + " - " + col }}
              </v-tab>
            </v-tabs>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      File field
                    </th>
                    <th class="text-left">
                      Database field
                    </th>
                    <th class="text-left">
                      Default value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itm, nam) in newVariables[selectedCollections[tabCol]]" :key="nam">
                    <td>{{ nam }}</td>
                    <td>
                      <v-combobox
                        v-model="newVariables[selectedCollections[tabCol]][nam].field"
                        dense
                        hide-details
                        :items="collectionsFields[selectedCollections[tabCol]]"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="newVariables[selectedCollections[tabCol]][nam].default"
                        dense
                        hide-details
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
        <v-form ref="dataForm">
          <!-- <v-subheader>{{ $t("collections") }}</v-subheader>
          <v-subheader>{{ $t("fields") }}</v-subheader> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"

import { EventBus } from "../../EventBus"

export default {
  components: {},
  data: () => ({
    tab: 0,
    tabCol: 0,
    variables: [],
    newVariables: {},
    selectedCollections: [],
    collectionsFields: {},
    newVariablesCollection: {},
    access: "public",
    file: null,
    recordId: "",
    collectionsValues: {},
    etag: "",
    show: false,
    title: "",
    record: null
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    inputRequiredFile() {
      const rule = []
      if (this.recordId === 0) {
        rule.push(v => !!v || this.$t("required"))
      }
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    tables() {
      return this.$store.getters.appSchema
    }
  },
  mounted() {},
  methods: {
    tabChanged(e) {
      this.$log("tabChanged ", e)
      if (e === 1) {
        this.$log("change this.newVariables = {} ", this.newVariables)
        //this.newVariables = {}
        this.variables.map(itm => {
          //this.$log("for ", itm)
          const col = itm.split("_")[0]
          let val = itm
          if (this.collectionsValues[col]) {
            val = this.collectionsValues[col] + " " + itm.replace(col + "_", "")
          }

          this.newVariablesCollection[val] = this.collectionsValues[col]
          if (this.newVariables[this.collectionsValues[col]] === undefined) {
            this.newVariables[this.collectionsValues[col]] = {}
          }
          if (this.newVariables[this.collectionsValues[col]][val] === undefined) {
            this.newVariables[this.collectionsValues[col]][val] = { field: "", default: "" }
          }
        })
        this.$log("this.newVariables ", this.newVariables)
        this.selectedCollections = []
        for (const itm in this.collectionsValues) {
          if (this.collectionsValues[itm] !== "") {
            if (!this.selectedCollections.includes(this.collectionsValues[itm])) {
              this.selectedCollections.push(this.collectionsValues[itm])
            }
            this.$log("this.collectionsValues[itm] ", this.collectionsValues[itm])
            this.generateCollectionFields(this.collectionsValues[itm])
          }
        }
        this.selectedCollections.push("")
        this.$log("this.selectedCollections ", this.selectedCollections)
      }
    },
    addData() {
      this.show = true
      this.recordId = 0
      this.title = ""
      this.file = null
      this.newVariables = {}
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.title = e.name
      this.access = e.access
      this.file = null
      this.tab = 0
      this.tabCol = 0
      this.variables = []
      this.newVariables = {}
      this.collectionsValues = {}
      this.$log("etag ", e)
      axios.get("app_template/" + this.recordId).then(response => {
        this.$log("app_template", response)
        this.$log("variables", Array.isArray(response.data.variables))
        this.record = response.data
        if (response.data.variables.variables) {
          this.variables = response.data.variables.variables
          // let record = { variables: this.variables, collections: this.collectionsValues, fields: this.newVariables }
          this.collectionsValues = response.data.variables.collections
          this.newVariables = response.data.variables.fields
        } else {
          this.variables = response.data.variables.default
          this.variables.map(itm => {
            const col = itm.split("_")[0]
            this.$set(this.collectionsValues, col, "")
            // this.collectionsValues[col] = ""
          })
          this.$log("this.variables ", this.variables)
          this.$log("this.collectionsValues ", this.collectionsValues)
        }
        this.etag = response.data._etag
      })
    },
    gettablesName(tab) {
      if (tab === "") {
        return "other"
      }
      return this.tables.find(itm => itm.collection === tab).title
    },
    generateCollectionFields(col) {
      this.$log("collectionChanged2 ", col)
      this.collectionsFields[col] = []
      this.generateList(this.tables.find(itm => itm.collection === col).content, "", this.collectionsFields[col])
    },
    generateList(rec, name, list) {
      for (const prop in rec) {
        let newname = ""
        if (name) {
          newname = name + "." + prop
        } else {
          newname = prop
        }
        if (rec[prop].schema) {
          if (rec[prop].type === "list") list.push(newname + "[]")
          this.generateList(rec[prop].schema, newname, list)
        } else {
          list.push(newname)
        }
      }
    },

    saveData() {
      this.$log("this.variables1 ", this.newVariables)
      this.$log("this.variables2 ", this.collectionsValues)
      const record = { variables: this.variables, collections: this.collectionsValues, fields: this.newVariables }
      this.$log("record ", record)
      if (this.$refs.dataForm.validate()) {
        if (this.recordId === 0) {
          this.$log("file1 ", this.file)
          const formData = new FormData()
          formData.append("name", this.title)
          formData.append("file", this.file)
          formData.append("path", this.file.name)
          formData.append("access", this.access)
          formData.append("company", this.companyId)
          formData.append("user", this.userId)
          axios
            .post("app_template/", formData, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          //this.record.variables = record
          this.$log("vars ", JSON.stringify(record))
          axios
            .patch(
              "app_template/" + this.recordId,
              { variables: record },
              {
                headers: {
                  "If-Match": this.etag
                }
              }
            )
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
